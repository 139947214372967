import { createRouter, createWebHashHistory } from 'vue-router'
const routerHistory = createWebHashHistory()
import home from "../pages/home"
import wxAuthorize from "@/pages/wxAuthorize";
const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: '/',
            redirect:'/wxAuthorize'
        },
        {
            path: '/form',
            component: home
        },
        {
            path: '/wxAuthorize',
            component: home
        },

    ]
})

export {
    router
}
