<template>
      <router-view></router-view>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
  }
}
</script>

<style>

</style>
