import axios from 'axios'

import {router}  from '../router/index';


class Http {


    static async request ({url,data,method="get",headers,responseType=''}) {
        return await axios({
            url: url,
            data: data,
            method: method,
            headers: headers,
            responseType: responseType,
        }).then((res)=>{
            return res;
        }).catch((error)=>{
            if (error.response) {
               if(error.response.status===401){
                   sessionStorage.removeItem("Token")
                   sessionStorage.removeItem("username")
                   sessionStorage.removeItem("id")
                   router.push('/login')
               }
                return error.response;
            } else if (error.request) {
                return error.request
            } else {
                return error.message
            }

            return  error.config
        })
    }
}

export {
    Http
}
