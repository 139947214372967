<template>
  <div class="container-wx">
    <div class="img-container">
      <img src="https://slj-img.oss-cn-beijing.aliyuncs.com/logo.png" class="logo" alt="基础图片">
      <p class="text-title">石榴家美居活动系统</p>
    </div>
  </div>
</template>

<script>
import {Activity} from "../../moudle/activity";

export default {
  name: "wxAuthorize",
  methods:{
    async getAuthUrl() {
     //
      // let res = await Activity.getWxAuthorizeUrl()
      window.location.href='https://hd.16jia.cn.com/#/home'
    }
  },
  mounted() {
    let tempId =  this.$route.query.temp
    let uid =  this.$route.query.uid
    sessionStorage.setItem("tempId",tempId)
    sessionStorage.setItem("uid",uid)
    this.getAuthUrl()
  }
}
</script>

<style scoped>
.container-wx{
  width: 100%;
  height: 100%;

}
.img-container{
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-top: 47%;
}
.logo{
  width: 140px;
  height: 140px;
}
.text-title{
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
