import {Http} from "@/utils/http";
import Qs from 'qs'
class Activity{


    /**
     * 查询活动信息
     * @param templateId 模板Id
     * @param uid uid
     * @returns
     */
    static async queryActivityMessage(templateId,uid){
        return  await Http.request({
            url:`/gcadmin/formActivity/activity/message?temp=${templateId}&uid=${uid}`,
            method:'GET',
        })
    }


    /**
     * 查询模板信息
     * @param templateId 模板ID
     * @returns
     */
    static async queryTemplateMessage(templateId){
        return  await Http.request({
            url:`/gcadmin/formTemplate/show/${templateId}`,
            method:'GET',
        })
    }

    /**
     * 获取分享签名
     * @param url url
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getSharSign(url){
        return  await Http.request({
            url:`/gcadmin/wx/getSign`,
            data:url,
            method:'POST',
        })
    }

    static async getWxAuthorizeUrl(){
        return  await Http.request({
            url:`/gcadmin/wx/wxAuthorize`,
            method:'GET',
        })
    }


    /**
     * 新增活动数据
     * @param formRowData 活动数据参数
     * @returns {Promise<*>}
     */
    static async addNewFormData(formRowData){
        return  await Http.request({
            url:`/gcadmin/formActivityRow/add`,
            data:formRowData,
            method:'POST',
        })
    }

    /**
     * 添加活动浏览记录
     * @param code 用户code
     * @param uid 活动UID
     * @param tempId 模板ID
     * @returns {Promise<*>}
     */
    static async addPageView(code,uid,tempId){
        return  await Http.request({
            url:`/gcadmin/formActivity/view/add?code=${code}&uid=${uid}&tempId=${tempId}`,
            method:'GET',
        })
    }
}
export {
    Activity
}
