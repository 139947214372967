<template>
  <div class="form-container">
    <template v-for="item of formMessageVo.backImg">
      <img :src="item" style="width: 100%;">
    </template>

    <div class="container-fluid">
      <form>
        <div class="form-group form"  v-for="(item,index) of template.templateContent">
          <label :for="index">{{item.title}}<span style="color:red"  v-if="item.isMust === 0">*</span></label>
          <input v-if="item.type===0 && item.isMust === 0" required v-model="activityData[item.subMark]" type="text" class="form-control" :name="item.subMark" :id="index" :placeholder="item.place">
          <input v-if="item.type===0 && item.isMust === 1"  v-model="activityData[item.subMark]" type="text" class="form-control" :name="item.subMark" :id="index" :placeholder="item.place">
          <input v-if="item.type===1 && item.isMust === 0" v-model="activityData[item.subMark]" type="date" class="form-control" :name="item.subMark" :id="index" :placeholder="item.place">
          <input v-if="item.type===1 && item.isMust === 1" v-model="activityData[item.subMark]" type="date" class="form-control" :name="item.subMark" :id="index" :placeholder="item.place">
          <div v-if="item.type === 2" class="radio-grid">
            <template v-for="(tag,index) of item.tags">
              <div v-if="item.type===2 && item .isMust === 0" class="radio-item">
                <input
                    v-model="checks"
                    type="checkbox" class="form-control"
                    :value="tag"
                    :id="index"
                    :placeholder="item.place">{{tag}}
              </div>
              <div v-if="item.type===2 && item .isMust === 1" class="radio-item">
                <input v-model="checks"  :value="tag" type="checkbox" class="form-control"  :id="index" :placeholder="item.place">{{tag}}
              </div>
            </template>
          </div>
          <div v-if="item.type === 3" class="radio-grid">
            <template v-for="(tag,index) of item.tags">
              <div v-if="item.type===3 && item .isMust === 0" class="radio-item">
                <input
                    v-model="radioS"
                    type="radio" class="form-control"
                    :value="tag"
                    :id="index"
                    :placeholder="item.place">{{tag}}
              </div>
              <div v-if="item.type===3 && item .isMust === 1" class="radio-item">
                <input v-model="radioS"  :value="tag" type="radio" class="form-control"  :id="index" :placeholder="item.place">{{tag}}
              </div>
            </template>
          </div>
        </div>
        <div @click="submit" class="weui-btn bg-primary btn">立即报名</div>
      </form>
    </div>
    <div class="footer">
      <p>Copyright © {{formMessageVo.copyRight}}</p>
    </div>
  </div>
</template>
<script>
import {Activity} from "../../moudle/activity";
import weui from 'weui.js'
export default {
  name: "home",
  data(){
    return{
      tempId:'',
      uid:'',
      formMessageVo:{
        activityTitle:'',
        backImg:'',
        shareTitle:'',
        shareDes:'',
        shareLogo:'',
        shareUrl:'',
        copyRight:''
      },
      template: {},
      submitData:{
        templateId:'',
        activityUid:'',
        activityData:{}
      },
      activityData:{},
      checks:[],
      radioS:[],
    }
  },
  methods:{


    async submit() {
      let templateContent =  this.template.templateContent
      for (let i = 0; i < templateContent.length; i++) {
        if(templateContent[i].type === 2){
          this.activityData[templateContent[i].subMark]=this.checks.toString()
        }
        if(templateContent[i].type === 3){
          this.activityData[templateContent[i].subMark]=this.radioS.toString()
        }
      }
      this.submitData.activityData=this.activityData
      let loading = weui.loading('loading', {
        className: 'custom-classname'
      });
      let res = await Activity.addNewFormData(this.submitData)
      if (res.status === 200){
        loading.hide(function() {
          weui.toast('报名成功', 8000);
        });
      }else {
        loading.hide(function() {
          weui.alert(res.data.message);
        });
      }
    },


    async loadFormMessage() {
      let res = await Activity.queryActivityMessage(this.tempId, this.uid)
      if(res.status === 200){
        this.formMessageVo =res.data.data
        document.title = this.formMessageVo.activityTitle
        this.initShare()
      }else {
        weui.alert("活动加载失败，请联系管理员!");
      }
    },
    /**
     * 加载模板
     */
    async loadTempLate() {
      let res = await Activity.queryTemplateMessage(this.tempId)
      if (res.status === 200) {
        this.template = res.data.data
        this.initForm(this.template)

      } else {
        weui.alert("模板加载失败，请联系管理员!");
      }
    },

    /**
     * 初始化表单
     */
    initForm(){
      this.submitData.templateId=this.tempId
      this.submitData.activityUid=this.uid //需在用户可能点击分享按钮前就先调用
    },

    /**
     * 添加记录
     */
    /*async addView() {
      await Activity.addPageView(this.submitData.code,sessionStorage.getItem("uid"), sessionStorage.getItem("tempId"))
    },*/

    /**
     * 初始化分享配置
     * @returns {Promise<void>}
     */
    async initShare() {
      let url = {
        getUrl: "https://hd.16jia.cn/#/wxAuthorize?temp="+this.tempId+"&uid="+this.uid
      }
      let res = await Activity.getSharSign(url)
      let that =this
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wx9f315dfb5440b3bc', // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: ["updateAppMessageShareData"] // 必填，需要使用的JS接口列表
      });
      //需在用户可能点击分享按钮前就先调用.
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: that.formMessageVo.shareTitle, // 分享标题
          desc: that.formMessageVo.shareDes, // 分享描述
          link: that.formMessageVo.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.formMessageVo.shareLogo, // 分享图标
          success: function () {}
        })
      });
    },


  },
  mounted() {
    let tempId =  this.$route.query.temp
    let uid =  this.$route.query.uid

    sessionStorage.setItem("tempId",tempId)
    sessionStorage.setItem("uid",uid)
    this.tempId =  sessionStorage.getItem("tempId")
    this.uid =  sessionStorage.getItem("uid")
    this.loadFormMessage()
    this.loadTempLate()
  }
}
</script>

<style scoped>
.form{
  margin-top: 20px;
}
.form-container{
  width: 100%;
}
.footer{
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
}
.btn{
  width: 98%!important;
  margin-top: 15px;
  margin-bottom: 15px;
}
.radio-grid{
  display: flex;
  flex-wrap: wrap;
  margin-left: 12px;
  margin-bottom: 28px;
}
.radio-item{
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 50%;
  font-size: 16px;
  margin-top: 16px;
}
.radio-item input{
  width: 22px;
  height: 22px;
}
</style>
